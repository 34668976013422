import React, { useEffect } from "react";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useConsumerStore } from "../../../store/consumer";
import { InputText } from "primereact/inputtext";

const ConsumerToggle = () => {
  const {
    consumerOptions,
    fetchConsumerOptions,
    selectedConsumer,
    switchConsumer,
  } = useConsumerStore();

  useEffect(() => {
    fetchConsumerOptions();
  }, []);

  const onSwitchConsumer = async (event: DropdownChangeEvent) => {
    switchConsumer(event.value?.id);
    // reload to fetch all the new data for a specific page
    location.reload();
  };

  const onlyOneConsumerAvailable = consumerOptions.length === 1;

  return (
    <div className="px-6 py-2 bg-white d-flex consumer-toggle">
      {onlyOneConsumerAvailable ? (
        <div
          className="text-sm text-overflow-ellipsis border-1 border-gray-300 rounded p-inputtext p-inputtext-sm"
          style={{
            maxWidth: 350,
          }}
        >
          {selectedConsumer?.name}
        </div>
      ) : (
        <Dropdown
          value={selectedConsumer}
          onChange={onSwitchConsumer}
          optionLabel="name"
          options={consumerOptions}
          style={{ minWidth: 170 }}
          className="dd-text-sm"
          /* panelClassName for drop down items class */
          panelClassName="dd-text-sm"
        />
      )}
    </div>
  );
};

export default ConsumerToggle;
