import React, { useEffect, useCallback } from "react";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useGlossaryStore } from "shared/store/glossary";
import { useConsumerStore } from "shared/store/consumer";
import { useLocalesStore } from "../../../../shared/store/locales";
import { getDataTestIdPtObject } from "../../../../shared/functions/utils";
import useLocalePairSelection from "../../../../shared/hooks/useLocalePairSelection";
import { NO_LANGUAGE_SELECTED } from "../../../../shared/constants";

export const GlossaryLanguagePairSelect = () => {
  const {
    isLoading: isLoadingFetchLanguagePairs,
    resetSelectedLanguagePair,
    fetchParsingRules,
    parsingRules,
    resetParsingRulesTableData,
    setSelectedLanguagePair,
  } = useGlossaryStore();

  const { consumerHasLocales, fetchConsumerHasLocales } = useConsumerStore();

  const { locales, fetchLocales } = useLocalesStore();

  useEffect(() => {
    fetchLocales();
    fetchConsumerHasLocales();

    // reset language pairs when component unmounts
    return resetSelectedLanguagePair;
  }, []);

  const {
    sourceLocaleOptions,
    targetLocaleOptions,
    selectedSourceLocaleId,
    setSelectedSourceLocaleId,
    selectedTargetLocaleId,
    setSelectedTargetLocaleId,
  } = useLocalePairSelection(consumerHasLocales, locales);

  const onSourceLanguageSelect = (e: DropdownChangeEvent) => {
    setSelectedSourceLocaleId(e.value);
    setSelectedTargetLocaleId(NO_LANGUAGE_SELECTED);
    resetSelectedLanguagePair();
    resetParsingRulesTableData();
  };

  const onTargetLanguageSelect = (e: DropdownChangeEvent) => {
    setSelectedTargetLocaleId(e.value);
  };

  const loadParsingRules = useCallback(() => {
    if (
      selectedSourceLocaleId !== NO_LANGUAGE_SELECTED &&
      selectedTargetLocaleId !== NO_LANGUAGE_SELECTED
    ) {
      const findLocaleById =
        (id: number) => (item: (typeof sourceLocaleOptions)[number]) =>
          item?.id === id;
      setSelectedLanguagePair({
        sourceLocaleId: selectedSourceLocaleId,
        targetLocaleId: selectedTargetLocaleId,
        sourceLocaleName:
          sourceLocaleOptions.find(findLocaleById(selectedSourceLocaleId))
            ?.name || "",
        targetLocaleName:
          sourceLocaleOptions.find(findLocaleById(selectedTargetLocaleId))
            ?.name || "",
      });

      fetchParsingRules(selectedSourceLocaleId, selectedTargetLocaleId);
    }
  }, [selectedSourceLocaleId, selectedTargetLocaleId]);

  return (
    <div className="container">
      <div className="row">
        {!parsingRules.length && (
          <h5 className="primary-blue">
            Please select a source and target language
          </h5>
        )}
      </div>
      {isLoadingFetchLanguagePairs ? (
        <></>
      ) : (
        <>
          <div className="row">
            <div className="col">
              <div className="row justify-content-center">
                <span>Source Language</span>
                <div className="row mt-2">
                  <Dropdown
                    id="sourceLang"
                    value={selectedSourceLocaleId}
                    options={sourceLocaleOptions}
                    onChange={onSourceLanguageSelect}
                    optionLabel="label"
                    placeholder="Select Language"
                    pt={{
                      select: getDataTestIdPtObject("sourceLanguageSelect"),
                      list: getDataTestIdPtObject("sourceLanguageList"),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-auto align-self-center mt-4">
              <i className="pi pi-arrow-right" />
            </div>
            <div className="col">
              <div className="row justify-content-center">
                <span>Target Language</span>
                <div className="row mt-2">
                  <Dropdown
                    id="sourceLang"
                    value={selectedTargetLocaleId}
                    options={targetLocaleOptions}
                    onChange={onTargetLanguageSelect}
                    optionLabel="label"
                    placeholder="Select Language"
                    pt={{
                      select: getDataTestIdPtObject("targetLanguageSelect"),
                      list: getDataTestIdPtObject("targetLanguageList"),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col flex justify-content-end">
              <Button
                label="Load Glossary"
                disabled={
                  selectedSourceLocaleId === NO_LANGUAGE_SELECTED ||
                  selectedTargetLocaleId === NO_LANGUAGE_SELECTED
                }
                onClick={loadParsingRules}
                {...getDataTestIdPtObject("loadGlossaryBtn")}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
