import React, { useCallback, useEffect, useState } from "react";
import "./style.css";
import { useLocalesStore } from "../../../../shared/store/locales";
import { useConsumerStore } from "../../../../shared/store/consumer";
import LanguagesSelect, {
  ExtendLocaleT,
} from "../../../../shared/components/LanguagesSelect";
import { Button } from "primereact/button";
import { FormalityT } from "../../../../shared/types/Formality";
import { ConsumerHasLocale } from "../../../../shared/types/ConsumerHasLocale";
import { FORMALITY_TYPES } from "../../../../shared/constants";
import { getDataTestIdPtObject } from "../../../../shared/functions/utils";
import {
  Permission,
  PermissionGroup,
} from "../../../../shared/types/UserPermission";

export type ExtendedConsumerHasLocaleT = {
  consumerHasLocaleId?: number;
} & ConsumerHasLocale;

const LanguagesTabBody = () => {
  const { locales, fetchLocales } = useLocalesStore();
  const {
    consumerHasLocales,
    fetchConsumerHasLocales,
    postConsumerHasLocales,
    hasPermission,
  } = useConsumerStore();

  const [isEditing, setIsEditing] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState<
    Array<ExtendedConsumerHasLocaleT>
  >([]);
  const [languagesWithExtendedState, setLanguagesWithExtendedState] = useState<
    Array<ExtendLocaleT>
  >([]);

  useEffect(() => {
    fetchLocales();
    fetchConsumerHasLocales();
  }, []);

  useEffect(() => {
    if (locales && consumerHasLocales) {
      // If locale does not have `isSelfServiceEnabled=true` flag or has no mtDisplayName (it will be both usually),
      // then we treat it as disabled for self-service, and won't allow consumer selecting such locale (consumer_has_locale table).
      const csspEnabledLocales = locales.filter(
        (item) => item.isSelfServiceEnabled && item.mtDisplayName
      );

      const selectedBELocales = consumerHasLocales.reduce<{
        [key: number | string]: {
          consumerHasLocaleId?: number;
          formalityType: FormalityT | undefined;
        };
      }>((acc, currentValue) => {
        acc[currentValue.localeId] = {
          consumerHasLocaleId: currentValue.id,
          formalityType: currentValue.formalityType,
        };
        return acc;
      }, {});
      const localesWithExtendedState: Array<ExtendLocaleT> = csspEnabledLocales
        .map((item) => {
          const selectedOnClientLocale = selectedLanguages.find(
            (el) => el.id == item.id
          );
          const formalityType =
            selectedOnClientLocale?.formalityType ||
            selectedBELocales[item.id]?.formalityType ||
            (item.isFormalitySupported
              ? FORMALITY_TYPES.FORMAL.value
              : undefined);
          return {
            ...item,
            consumerHasLocale: !!selectedBELocales[item.id],
            consumerHasLocaleId:
              selectedBELocales[item.id]?.consumerHasLocaleId,
            formalityType,
          };
        })
        .sort(({ mtDisplayName: a }, { mtDisplayName: b }) => {
          if (a && b) {
            return a.localeCompare(b);
          }
          return 0;
        });
      setLanguagesWithExtendedState(localesWithExtendedState);
    }
  }, [locales, selectedLanguages, consumerHasLocales]);

  const onEditSaveBtnClick = useCallback(async () => {
    if (!isEditing) {
      setIsEditing(true);
      return;
    }

    // Filter out languages that were not changed before submitting to back-end
    const languagesToSubmit = selectedLanguages.filter((selectedItem) => {
      const languageFromDB = consumerHasLocales.find(
        ({ id }) => selectedItem.consumerHasLocaleId == id
      );

      if (
        languageFromDB &&
        languageFromDB.formalityType == selectedItem.formalityType
      ) {
        // don't include language if formality is the same as in database
        return false;
      }

      return true;
    });

    if (languagesToSubmit.length == 0) {
      setSelectedLanguages([]);
      setIsEditing(false);
      return;
    }

    await postConsumerHasLocales(languagesToSubmit);
    setSelectedLanguages([]);
    setIsEditing(false);
  }, [selectedLanguages, languagesWithExtendedState, isEditing]);

  const onCancelEditBtnClick = () => {
    setSelectedLanguages([]);
    setIsEditing(false);
  };

  return (
    <div className="mt-7 col-lg-8 m-auto">
      <LanguagesSelect
        selectedItems={selectedLanguages}
        setSelectedItems={setSelectedLanguages}
        languages={languagesWithExtendedState}
        disabled={!isEditing}
        className="max-h-30rem"
      />
      {hasPermission(PermissionGroup.SETTINGS, Permission.EDIT) && (
        <div className="d-flex justify-content-end mt-4">
          {isEditing && (
            <Button
              className="mr-2"
              label="Cancel"
              onClick={onCancelEditBtnClick}
              severity="secondary"
              outlined
              pt={{ root: getDataTestIdPtObject("cancelEdit") }}
            />
          )}
          <Button
            className="edit-save-btn"
            label={isEditing ? "Save changes" : "Edit Languages"}
            onClick={onEditSaveBtnClick}
            pt={{ root: getDataTestIdPtObject("saveEdit") }}
          />
        </div>
      )}
    </div>
  );
};

export default LanguagesTabBody;
