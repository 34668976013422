import { StateSliceCreator } from "../../utils/types";
import { ConsumerOption } from "../../../types/consumer/ConsumerToggle";
import {
  createCurrentConsumerSlice,
  currentConsumerSliceT,
} from "./currentConsumerSlice";
import { ConsumerAPI } from "../../../api/api-golinguist/consumerAPI";

export type ConsumerToggleSliceT = {
  consumerOptions: ConsumerOption[];
  selectedConsumer?: ConsumerOption;
  fetchConsumerOptions: () => void;
  switchConsumer: (id: string) => void;
} & currentConsumerSliceT;

export const createConsumerToggleSlice: StateSliceCreator<
  ConsumerToggleSliceT
> = (set, get) => ({
  ...createCurrentConsumerSlice(set, get),
  consumerOptions: [],
  selectedConsumer: undefined,
  fetchConsumerOptions: async () => {
    const { consumerOptions, encryptedSelectedConsumerId } =
      await ConsumerAPI.getConsumerToggleData();
    set({
      consumerOptions,
      selectedConsumer: consumerOptions.find(
        ({ id }) => id === encryptedSelectedConsumerId
      ),
    });
  },
  switchConsumer: async (id: string) => {
    await ConsumerAPI.postSelectedConsumer(id);
    await get().fetchCurrentConsumer();
  },
});
