import React from "react";
import { TableProps } from "./TableContents";
import { Button } from "primereact/button";
import {
  ParsingRule,
  ParsingRuleUpdateResponse,
} from "../../../../shared/types/ParsingRule";
import { getBodyClassName, getColumns } from "../../utils/GlossaryTableUtils";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Checkbox } from "primereact/checkbox";
import { useGlossaryStore } from "../../../../shared/store/glossary";
import { getDataTestIdPtObject } from "../../../../shared/functions/utils";
import { GLOSSARY_TABLE_COLUMN_FIELD } from "../../../../shared/constants";

interface PublishProps extends TableProps {
  setShowEditConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  isPublished: boolean;
  setIsPublished: React.Dispatch<React.SetStateAction<boolean>>;
  response: ParsingRuleUpdateResponse | null;
}

export const GlossaryPublishReport = (props: PublishProps) => {
  const {
    selectedLanguagePair,
    fetchParsingRules,
    isLoading: isLoadingFetchParsingRules,
  } = useGlossaryStore();

  const [errorCount, setErrorCount] = React.useState<number>(0);
  const [expandedRow, setExpandedRow] = React.useState<ParsingRule | null>(
    null
  );

  React.useEffect(() => {
    let count = 0;
    props.modifiedParsingRules.forEach((parsingRule: ParsingRule) => {
      if (parsingRule.error) {
        count++;
      }
    });
    setErrorCount(count);
  }, []);

  const caseSensitiveStatusBodyTemplate = (rowData: ParsingRule) => {
    return (
      <Checkbox
        className="disabledCheckbox"
        disabled
        checked={rowData.caseInsensitive}
      />
    );
  };

  const noTranslateStatusBodyTemplate = (rowData: ParsingRule) => {
    return (
      <Checkbox
        className="disabledCheckbox"
        disabled
        checked={rowData.applyNoTranslateTag}
      />
    );
  };

  const statusBodyTemplate = (rowData: ParsingRule) => {
    return <div>{rowData.status}</div>;
  };

  const getColumnBody = (field: string) => {
    switch (field) {
      case GLOSSARY_TABLE_COLUMN_FIELD.applyNoTranslate: {
        return noTranslateStatusBodyTemplate;
      }
      case GLOSSARY_TABLE_COLUMN_FIELD.caseInsensitive: {
        return caseSensitiveStatusBodyTemplate;
      }
      case GLOSSARY_TABLE_COLUMN_FIELD.status: {
        return statusBodyTemplate;
      }
      default:
        break;
    }
  };

  const infoColumnTemplate = (rowData: ParsingRule) => {
    const isExpanded = expandedRow && rowData.id === expandedRow.id;
    if (rowData.error) {
      return (
        <div
          onClick={async () => {
            if (isExpanded) {
              setExpandedRow(null);
            } else {
              setExpandedRow(rowData);
            }
          }}
        >
          {isExpanded ? "Hide Info" : "Show Info"}
        </div>
      );
    }
    return <div className="lightGrey">Show Info</div>;
  };

  const rowExpansionTemplate = (parsingRule: ParsingRule) => {
    if (parsingRule.error) {
      return (
        <div className="confirmationError pt-3 darkGrey d-flex justify-content-between align-items-center">
          <span>{parsingRule.error}</span>
          <div
            className="copyButton"
            onClick={() =>
              parsingRule.error &&
              navigator.clipboard.writeText(parsingRule.error)
            }
          >
            <i className="pi pi-clone p-1" />
          </div>
        </div>
      );
    }
    return;
  };

  // primereact components sometimes pick up inline styles better than CSS classes, which is why they are used below
  return (
    <div>
      <h4 className="publishReportHeading mt-3">
        <strong>
          Publish Report: Glossary Terms{" "}
          {selectedLanguagePair && selectedLanguagePair.sourceLocaleName}{" "}
          <i className="pi pi-arrow-right" style={{ fontSize: "1rem" }}></i>{" "}
          {selectedLanguagePair && selectedLanguagePair.targetLocaleName}
        </strong>
      </h4>
      <p
        className="pb-2 darkGrey"
        {...getDataTestIdPtObject("glossaryUpdatedTime")}
      >
        Glossary updated {new Date().toLocaleString()}
      </p>
      {errorCount > 0 && (
        <p className="darkGrey">
          {errorCount ? errorCount : ""} error(s) occurred while publishing to
          your glossary. Click &quot;show info&quot; for more information.
        </p>
      )}

      <DataTable
        value={props.modifiedParsingRules}
        responsiveLayout="scroll"
        loading={isLoadingFetchParsingRules}
        dataKey="id"
      >
        {selectedLanguagePair &&
          getColumns(selectedLanguagePair).map(({ field, header }) => {
            return (
              <Column
                key={field}
                field={field}
                header={header}
                headerStyle={{ background: "#F9FAFB" }}
                body={getColumnBody(field)}
                bodyClassName={getBodyClassName(field)}
                pt={{
                  headerTitle: getDataTestIdPtObject(field + "ColumnTitle"),
                  bodyCell: getDataTestIdPtObject(field + "Cell"),
                }}
              />
            );
          })}
        <Column
          expander
          headerStyle={{ background: "#F9FAFB" }}
          body={infoColumnTemplate}
        />
      </DataTable>

      {expandedRow && expandedRow.error && rowExpansionTemplate(expandedRow)}

      <span className="d-flex justify-content-end">
        <Button
          label="Return to Glossary"
          className="m-3"
          onClick={async () => {
            if (selectedLanguagePair) {
              await props.setModifiedParsingRules([]);
              await fetchParsingRules(
                selectedLanguagePair.sourceLocaleId,
                selectedLanguagePair.targetLocaleId
              );
              await props.setShowEditConfirmation(false);
              await props.setIsPublished(false);
            }
          }}
        />
      </span>
    </div>
  );
};
