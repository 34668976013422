import axios, { AxiosError, CreateAxiosDefaults } from "axios";
import { hideLoadingOverlay } from "../functions/utils";
import { getGoLinguistDomain } from "./utils";
import { ServerErrorResp } from "../types/ServerError";
import useAppErrorsState from "../store/app-errors";

const errorHandler = (errResp: AxiosError<ServerErrorResp>) => {
  const statusCode = errResp.response?.status;

  if (statusCode && statusCode === 401) {
    window.location.href = getGoLinguistDomain() + "linguistnow/login";
    return;
  }

  useAppErrorsState
    .getState()
    .setServerError(errResp.response?.data.error, statusCode);

  hideLoadingOverlay();
  // throw error for custom error handling up the stack
  throw errResp;
};

export const createAxiosInstance = (config: CreateAxiosDefaults) => {
  const instance = axios.create(config);
  instance.interceptors.response.use(undefined, errorHandler);

  return instance;
};
