import { StateSliceCreator } from "../../utils/types";
import {
  createCurrentConsumerSlice,
  currentConsumerSliceT,
} from "./currentConsumerSlice";
import { ConsumerHasLocale } from "../../../types/ConsumerHasLocale";
import TrialSubAPI from "../../../api/api-phase-1/trialAndSubscriptionAPI";
import { ExtendedConsumerHasLocaleT } from "../../../../pages/settings/components/languages/LanguagesTabBody";
import { ACTION_STATUS, FORMALITY_TYPES } from "../../../constants";

export type consumerHasLocalesSliceT = {
  consumerHasLocales: ConsumerHasLocale[];
  fetchConsumerHasLocales: () => void;
  postConsumerHasLocales: (
    locales: ExtendedConsumerHasLocaleT[]
  ) => Promise<void>;
} & currentConsumerSliceT;

export const createConsumerHasLocalesSlice: StateSliceCreator<
  consumerHasLocalesSliceT
> = (set, get) => ({
  ...createCurrentConsumerSlice(set, get),
  consumerHasLocales: [],
  fetchConsumerHasLocales: async () => {
    if (get().currentConsumer.encryptedSelectedConsumerId.length === 0) {
      await get().fetchCurrentConsumer();
    }

    const response = await TrialSubAPI.getConsumerHasLocales(
      get().currentConsumer.encryptedSelectedConsumerId
    );
    set(() => ({
      consumerHasLocales: response.consumerHasLocales || [],
    }));
  },
  postConsumerHasLocales: async (locales) => {
    if (locales.length === 0) return;

    if (get().currentConsumer.encryptedSelectedConsumerId.length === 0) {
      await get().fetchCurrentConsumer();
    }

    const consumerHasLocalesForApi = locales.map((item) => {
      return {
        id: item.consumerHasLocaleId || null,
        localeId: item.localeId || null,
        // formalityType defaults to "FORMAL"?
        formalityType: item.formalityType || FORMALITY_TYPES.FORMAL.value,
        status: item.consumerHasLocaleId
          ? ACTION_STATUS.update
          : ACTION_STATUS.create,
      } as const;
    });

    const resp = await TrialSubAPI.createAndUpdateConsumerHasLocales(
      get().currentConsumer.encryptedSelectedConsumerId,
      consumerHasLocalesForApi
    );

    if (resp.status == "failed") {
      console.error("Failed to update languages");
      return;
    }

    if (resp.status == "success" || resp.status == "partial success") {
      const updatedLocalesMap = resp.consumerHasLocales.reduce((acc, value) => {
        acc[value.id] = value;
        return acc;
      }, {} as { [key: number]: ConsumerHasLocale });

      set({
        consumerHasLocales: get().consumerHasLocales.map((chl) => {
          if (updatedLocalesMap[chl.id]) {
            return updatedLocalesMap[chl.id];
          }
          return chl;
        }),
      });
    }
  },
});
